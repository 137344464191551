a.meanmenu-reveal {
     display: none
}

.mean-container .mean-bar {
     background: transparent none repeat scroll 0 0;
     float: left;
     min-height: 42px;
     padding: 0;
     position: relative;
     width: 100%;
     z-index: 99;
}

.mean-container a.meanmenu-reveal {
     color: #ffffff;
     cursor: pointer;
     display: block;
     float: right;
     font-family: "montserratlight";
     font-size: 1px;
     height: 22px;
     line-height: 22px;
     padding: 13px 0 11px;
     right: 0;
     text-decoration: none;
     text-indent: -9999em;
     top: 0;
     width: 25px;
}

.mean-container a.meanmenu-reveal span {
     background: #ffffff none repeat scroll 0 0;
     display: block;
     height: 1px;
     margin-top: 7px;
     transition: all 0.3s ease 0s;
}

.mean-container a.meanmenu-reveal span:first-child {
     margin-top: 3px;
}

.mean-container .mean-nav {
     float: left;
     width: 100%;
     background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0
}

.mean-container .mean-nav ul {
     padding: 0;
     margin: 0;
     width: 100%;
     list-style-type: none
}

.mean-container .mean-nav ul li {
     background: #f0f0f0 none repeat scroll 0 0;
     float: left;
     position: relative;
     width: 100%;
     border-top: 1px solid #f0f0f0;
}

.mean-container .mean-nav ul li a {
     background: #ffffff none repeat scroll 0 0;
     color: #444;
     display: block;
     float: left;
     font-size: 13px;
     font-weight: 600;
     line-height: 20px;
     margin: 0;
     padding: 10px 5%;
     text-align: left;
     text-decoration: none;
     text-transform: uppercase;
     width: 90%;
}

.mobile-menu-area {
     background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0
}

.mean-container .mean-nav ul li li a {
     color: #444;
     font-weight: normal;
     opacity: 1;
     padding: 1em 10%;
     text-shadow: none !important;
     text-transform: capitalize;
     visibility: visible;
     width: 80%;
}

.mean-container .mean-nav ul li.mean-last a {
     border-bottom: 0;
     margin-bottom: 0
}

.mean-container .mean-nav ul li li li a {
     width: 70%;
     padding: 1em 15%
}

.mean-container .mean-nav ul li li li li a {
     width: 60%;
     padding: 1em 20%
}

.mean-container .mean-nav ul li li li li li a {
     width: 50%;
     padding: 1em 25%
}

.mean-container .mean-nav ul li a:hover {
     color: #B5876D;
}

.mean-container .mean-nav ul li a.mean-expand {
     border: 0 none !important;
     font-weight: 600;
     height: 18px;
     margin-top: 0;
     padding: 11px 12px !important;
     position: absolute;
     right: 0;
     text-align: center;
     top: 0;
     width: 26px;
     z-index: 2;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
     background: #f8f8f8;
}

.mean-container .mean-push {
     float: left;
     width: 100%;
     padding: 0;
     margin: 0;
     clear: both
}

.mean-nav .wrapper {
     width: 100%;
     padding: 0;
     margin: 0
}

.mean-container .mean-bar,
.mean-container .mean-bar * {
     -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
     box-sizing: content-box
}

.mean-remove {
     display: none !important
}

.mean-container .mean-bar::before {
     color: #ffffff;
     content: "MENU";
     font-size: 18px;
     font-weight: 600;
     left: 0;
     position: absolute;
     top: 13px;
}