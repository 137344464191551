.banner-content h1 {
     color: #fff;
     font-size: 60px;
     margin-bottom: 10px;
     text-transform: uppercase
}

.banner-content p {
     margin-bottom: 39px;
     color: #fff
}

.banner-btn {
     clear: both;
     display: block;
}

.banner-btn a {
     display: inline-block;
     font-size: 18px;
     font-weight: 700;
     line-height: 48px;
     padding: 0 42px;
     text-transform: uppercase;
     background: #fff;
     color: #b5876d
}

.banner-btn a:before {
     background: rgba(181, 135, 109, 1);
     bottom: 0;
     content: "";
     left: 0;
     position: absolute;
     right: 0;
     top: 0;
     -webkit-transform: scaleY(0);
     transform: scaleY(0);
     -webkit-transform-origin: 50% 0 0;
     transform-origin: 50% 0 0;
     -webkit-transition-duration: 0.5s;
     transition-duration: 0.5s;
     -webkit-transition-property: -webkit-transform;
     transition-property: -webkit-transform;
     transition-property: transform;
     transition-property: transform, -webkit-transform;
     -webkit-transition-timing-function: ease-out;
     transition-timing-function: ease-out;
     z-index: -1;
}

.banner-btn a:hover,
.banner-btn a:focus,
.banner-btn a:active {
     color: #fff
}

.banner-btn a:hover:before,
.banner-btn a:focus:before,
.banner-btn a:active:before {
     -webkit-transform: scaleY(1);
     transform: scaleY(1);
     -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
     transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.slider-area .carousel-item.active .banner-content h1,
.slider-area .carousel-item.active .banner-content p,
.slider-area .carousel-item.active .banner-content .banner-btn {
     animation: 1500ms ease-in-out 0s normal both 1 running fadeInUp;
}

.slider-area .carousel-item.active .banner-content h1 {
     animation-delay: 1000ms
}

.slider-area .carousel-item.active .banner-content p {
     animation-delay: 2000ms
}

.slider-area .carousel-item.active .banner-content .banner-btn {
     animation-delay: 3000ms
}

.carousel-caption {
     top: 40%;
}