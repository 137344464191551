/* -----------------------------------
Template Name: Oestin
Template URI: https://devitems.com/html/oestin-preview/
Description: This is html5 template
Author: HasTech
Author URI: https://devitems.com/
Version: 1.0
NOTE: This is Footer style file. All footer related style included in this file.

--------------------------------------*/
.footer-area {
  color: #f7f7f7;
}

.footer-widget-area {
  padding: 75px 0 0px;
}

.footer-logo > a {
  display: inline-block;
}

.footer-logo {
  margin-bottom: 29px;
}

.social-icons a {
  border: 1px solid #b1b1b1;
  color: #b1b1b1;
  display: inline-block;
  font-size: 20px;
  height: 33px;
  line-height: 31px;
  margin-right: 13px;
  text-align: center;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 33px;
}

.social-icons > a:last-child {
  margin-right: 0;
}

.social-icons > a:hover {
  background: #b5876d none repeat scroll 0 0;
  border-color: #b5876d;
  color: #ffffff;
}

/* .social-icons {
  margin-top: 43px;
} */

.single-footer-widget > h3 {
  color: #ffffff;
  font-size: 30px;
  line-height: 14px;
  margin-bottom: 45px;
  /* text-transform: uppercase; */
}

.footer-list li {
  margin-bottom: 4px;
}

.footer-list a {
  color: #f7f7f7;
  font-size: 15px;
  line-height: 26px;
}

.footer-list li a {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.footer-list li a:hover {
  color: #b5876d;
}

.c-info > span:last-child {
  display: block;
  font-size: 15px;
  line-height: 24px;
  padding-left: 50px;
}

.c-info > span:first-child {
  border: 1px solid #ffffff;
  color: #b5876d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 33px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 7px;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
  width: 33px;
}

.c-info > span:first-child i {
  display: table-cell;
  vertical-align: middle;
}

.c-info {
  margin-bottom: 17px;
  /* overflow: hidden; */
  position: relative;
}

.c-info:hover > span:first-child {
  background: #ffffff none repeat scroll 0 0;
}

.instagram-image {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.footer-img a img {
  width: 100%;
  object-fit: cover;
}

.footer-img a {
  width: 100%;
}

.footer-img a,
.post-img a {
  display: inline-block;
  position: relative;
}

.footer-img a:before,
.post-img a:before {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 100%;
  content: "";
}

.footer-img a:hover:before,
.post-content:hover a:before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
}

.footer-img a:after,
.post-img a:after {
  color: #ffffff;
  content: "ï†Ž";
  font-family: "Material-Design-Iconic-Font";
  font-size: 22px;
  left: 50%;
  margin: auto;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.footer-img a:hover:after,
.post-content:hover a:after {
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.footer-bottom-area {
  padding: 24px 0;
}

.footer-text span a {
  color: #b5876d;
}

.footer-text span a:hover {
  border-bottom: 1px solid #b5876d;
}

.footer-text > span {
  display: block;
}
