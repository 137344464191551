/* -----------------------------------
Template Name: Oestin
Template URI: https://devitems.com/html/oestin-preview/
Description: This is html5 template
Author: HasTech
Author URI: https://devitems.com/
Version: 1.0
NOTE: This is main style sheet of template, 
This file contains the styling for the actual Template. 
Please do not change anything here! write in a custom.css file if required!

*/
/*================================================
[  Table of contents  ]
================================================
01. General
02. Text color
03. Input
04. Scrollup
05. Basic margin padding
06. Page section padding 
07. Page section margin 
08. Custom cloumn
09. Background variation set 
10. Section Title
11. Pagination
12. Breadcrumb
 
======================================
[ End table content ]
======================================*/

/*************************
    01. General
*************************/
@import url("https://fonts.googleapis.com/css?family=Lato:400,700|Open+Sans|Raleway:400,700");
@font-face {
  font-family: "BritishHair";
  src: url("../fonts/british_shorthair/British\ Shorthair.otf")
    format("truetype");
}
@font-face {
  font-family: "CompassLT";
  src: url("../fonts/Compass/Compass.ttf") format("truetype");
}
html,
body {
  height: 100%;
}

body {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "CompassLT";
}
.slider-area p {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* font-family: "Lato", sans-serif; */
  font-family: "CompassLT";
  font-weight: bold; /* Increase the thickness of the text */
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.clear {
  clear: both;
}

.fix {
  overflow: hidden;
}

.block {
  display: block;
}

.img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: auto;
}

.fit-image {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #444;
  margin: 0;
  font-family: "BritishHair", sans-serif;
  font-weight: 700;
}

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.btn {
  border-radius: 0px;
  font-size: 14px;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: 0px solid;
}

a:focus,
a:hover {
  color: #303030;
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0 none;
}

a,
button,
input {
  outline: medium none;
  color: #606060;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

label {
  font-size: 15px;
  font-weight: 400;
  color: #606060;
}

*::-moz-selection {
  background: #b3d4fc;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  color: #fff;
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
  color: #fff;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.mark,
mark {
  background: #4fc1f0 none repeat scroll 0 0;
  color: #ffffff;
  font-weight: 600;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

p {
  line-height: 24px;
  font-size: 15px;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #606060;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #606060;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #606060;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #606060;
}

.default-btn {
  background: #b5876d none repeat scroll 0 0;
  color: #ffffff;
  /* font-family: "Raleway", sans-serif; */
  font-family: "CompassLT", sans-serif;

  font-size: 14px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  transform: perspective(1px) translateZ(0px);
  transition-duration: 0.2s;
  transition-property: color;
  vertical-align: middle;
  display: inline-block;
}

.default-btn:before {
  background: rgba(255, 255, 255, 1);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleY(0);
  transform-origin: 50% 0 0;
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: ease-out;
  z-index: -1;
}

.default-btn:hover:before,
.default-btn:focus:before,
.default-btn:active:before {
  transform: scaleY(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.default-btn:hover,
.default-btn:focus,
.default-btn:active {
  color: #b5876d;
}

input,
select,
textarea {
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

/*************************
    02. Text color
*************************/
.text-white {
  color: #fff;
}

.text-theme {
  color: #b5876d;
}

/*************************
    03. Input
*************************/
input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none rgba(0, 0, 0, 0);
  box-shadow: none;
  color: #606060;
  font-size: 14px;
  height: 40px;
  padding-left: 10px;
  width: 100%;
}

select {
  background: #ffffff none repeat scroll 0 0;
  border: 0 none;
  box-shadow: none;
  color: #626262;
  height: 100%;
  padding-left: 10px;
  width: 100%;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none rgba(0, 0, 0, 0);
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4fc1f0;
  outline: none;
}

::-moz-placeholder {
  color: #444;
  font-size: 13px;
}

/*************************
    04. Scrollup
*************************/
#scrollUp {
  background: #b5876d none repeat scroll 0 0;
  bottom: 40px;
  color: #ffffff;
  cursor: pointer;
  display: none;
  font-size: 28px;
  height: 40px;
  line-height: 37px;
  position: fixed;
  right: 12px;
  text-align: center;
  width: 38px;
  z-index: 9999;
}

#scrollUp:hover {
  background-color: #263238;
}

/*************************
    05. Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
        Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
        Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-n60 {
  margin-bottom: -60px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-150 {
  margin-left: 150px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-bm-90 {
  padding-bottom: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-180 {
  padding-top: 180px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

/*************************
        Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

/***************************
    06. Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

/***************************
    07. Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

/***************************
    08. Custom cloumn
****************************/
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10 {
  float: left;
}

.col-1 {
  width: 10%;
}

.col-2 {
  width: 20%;
}

.col-3 {
  width: 30%;
}

.col-4 {
  width: 40%;
}

.col-5 {
  width: 50%;
}

.col-6 {
  width: 60%;
}

.col-7 {
  width: 70%;
}

.col-8 {
  width: 80%;
}

.col-9 {
  width: 90%;
}

.col-10 {
  width: 100%;
}

/*************************************
    09. Background variation set 
**************************************/
.bg-white {
  background: #fff;
}

.bg-black {
  background: #010101;
}

.bg-light {
  background: #f1f1f1 none repeat scroll 0 0;
}

.bg-dark {
  background: #131313 none repeat scroll 0 0;
}

.transparent-bg {
  background: transparent;
}

.bg-1,
.bg-2,
.bg-3,
.bg-4,
.bg-5 {
  background-attachment: scroll;
  background-clip: initial;
  background-color: rgba(0, 0, 0, 0);
  background-origin: initial;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* .bg-1 {
     background-image: url(../../img/bg/1.jpg);
}

.bg-2 {
     background-image: url("../../img/bg/2.jpg");
}

.bg-3 {
     background-image: url("../../img/bg/3.jpg");
}

.bg-4 {
     background-image: url("../../img/bg/3.jpg");
}

.bg-5 {
     background-image: url("../../img/bg/5.jpg");
     background-position: 51% bottom;
} */

.overlay,
.overlay-dark,
.overlay-dark-2 {
  position: relative;
}

.overlay:before,
.overlay-dark:before,
.overlay-dark-2:before {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.overlay-dark:before {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
}

.overlay-dark-2:before {
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
}

/*************************************
    10. Section Title
**************************************/
.section-title {
  padding: 0 87px 37px;
  position: relative;
}

.section-title h3 {
  line-height: 17px;
  margin-bottom: 23px;
  /* text-transform: uppercase; */
}

/*************************************
    11. Pagination
**************************************/
.pagination {
  margin: 0;
  padding: 0;
}

.pagination-content {
  display: flex;
  justify-content: center;
  line-height: 0;
  padding-top: 7px;
}

.pagination-content .pagination li a {
  background: #ffffff none repeat scroll 0 0;
  border: 0 none;
  color: #444444;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 15px;
  margin: 0 7px;
  padding: 0;
  text-align: center;
}

.pagination-content .pagination li a:hover,
.pagination-content .pagination li.active a {
  color: #b5876d;
  background: transparent;
}

.pagination-content .pagination li.active a {
  bottom: 4px;
  font-size: 30px;
}

/*************************************
    12. Breadcrumb
**************************************/
.breadcrumb-area {
  padding: 400px 0 80px;
}

.breadcrumb-text {
  position: relative;
  z-index: 1;
}

.breadcrumb-bar .breadcrumb {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  line-height: 12px;
  margin: 0;
  padding: 0 0 86px 0;
  margin-top: -2.5%;
  text-align: left;
}

.breadcrumb-text h2 {
  color: #ffffff;
  font-size: 36px;
  line-height: 26px;
  margin-bottom: 19px;
  /* text-transform: uppercase; */
}

.breadcrumb-text p {
  color: #fff;
  margin-bottom: 7px;
}

.breadcrumb-bar li {
  color: #b5876d;
  font-family: "BritishHair";
  font-size: 18px;
  line-height: 14px;
  margin: 0 18px;
  position: relative;
  text-transform: capitalize;
}
.breadcrumb-text h2 {
  font-family: "BritishHair";
}

.breadcrumb-bar .breadcrumb > li:first-child::before,
.breadcrumb-bar .breadcrumb > li:first-child::after {
  color: #ffffff;
  content: "/";
  display: inline-block;
  position: absolute;
  right: -18px;
  top: 0;
}

.breadcrumb-bar .breadcrumb > li:first-child::after {
  right: -28px;
}

.breadcrumb-bar .breadcrumb > li + li:before {
  display: none;
}

.breadcrumb-bar a {
  color: #fff;
}

.breadcrumb-bar a:hover {
  color: #b5876d;
}

.breadcrumb-bar li:first-child {
  margin-left: 0;
}

/*************************
 Bootstrap Custom Container
************************/
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
}

.wd-100 {
  width: 100%;
}
.col-lg-3 {
  padding: 0;
}

.mb-4 {
  margin-bottom: 0 !important;
}

.item-gallery {
  position: relative;
  height: 100%;
}
.item-gallery img {
  height: 100%;
}

.hover-gallery {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
}

.item-gallery:hover .hover-gallery {
  display: block;
}

.gallery-icon a {
  position: relative;
  display: inline-block;
}

.gallery-icon a span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease-in-out;
}

.gallery-icon a span.s-img {
  opacity: 0;
}

.gallery-icon a:hover span.p-img {
  opacity: 0;
}

.gallery-icon a:hover span.s-img {
  opacity: 1;
}

.gallery-icon a:first-child {
  margin-right: 20px;
}

.gallery-icon a:last-child {
  margin-left: 20px;
}
