/* -----------------------------------
Template Name: Oestin 
Template URI: https://devitems.com/html/oestin-preview/
Description: This is html5 template
Author: HasTech
Author URI: https://devitems.com/
Version: 1.0
NOTE: This is Header style file. All Header related style included in this file.

--------------------------------------*/
.header-area:after {
  background: #ffffff none repeat scroll 0 0;
  content: "";
  height: 141px;
  left: -5%;
  position: absolute;
  top: 0;
  -webkit-transform: skewX(-34deg);
  transform: skewX(-34deg);
  width: 28%;
}

.logo {
  padding-top: 46px;
  position: relative;
  z-index: 9;
}

.logo a {
  display: inline-block;
}

.logo img {
  transition: all 0.5s ease 0s;
  width: 100%;
}

.header-area {
  background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
  position: relative;
}

.fixed {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.header-contact > span {
  color: #ffffff;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
}

.header-contact > span.text-theme {
  color: #ffffff;
  padding-right: 9px;
}

.header-top {
  border-bottom: 1px solid #777777;
}

.header-contact {
  float: left;
  padding: 16px 0;
}

.header-links {
  float: right;
  padding: 14px 0;
}

.header-links > a {
  color: #ffffff;
  font-size: 18px;
  margin: 0 13px;
}

.header-links > a:last-child {
  margin-right: 0;
}

.header-links > a:hover {
  color: #b5876d;
}

.mainmenu-area {
  background: rgba(237, 237, 237, 0.35) none repeat scroll 0 0;
  position: relative;
}

.main-menu ul li {
  display: inline-block;
  margin: 0 13px;
  padding: 26px 0;
  position: relative;
}

.main-menu ul li:first-child {
  margin-left: 0;
}

.main-menu ul li:last-child {
  margin-right: 0;
}

.main-menu ul li a {
  color: #ffffff;
  position: relative;
}

.main-menu nav > ul > li:hover > a {
  color: #b5876d;
}

.main-menu ul li ul.submenu {
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  left: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  padding: 19px 15px 14px;
  position: absolute;
  text-align: left;
  top: 90%;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 210px;
  z-index: -9;
}

.main-menu ul li:hover > ul.submenu {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
  z-index: 9;
  top: 100%;
}

.main-menu ul li ul.submenu li {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
}

.main-menu ul li ul.submenu li a {
  border-radius: 2px;
  color: #444;
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  padding: 8px 15px;
  text-transform: capitalize;
}

.main-menu ul li ul.submenu li:hover > a {
  background: #b5876d none repeat scroll 0 0;
  color: #ffffff;
}

.main-menu ul li ul.submenu.megamenu {
  padding: 15px 20px;
  right: auto;
  white-space: nowrap;
  width: auto;
}

.main-menu ul li ul.submenu.megamenu > li {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  width: 160px;
}

.main-menu ul li ul.submenu.megamenu > li > a {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.main-menu.text-left span {
  float: right;
}

.header-sticky.sticky {
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  background: rgba(0, 0, 0, 0.68) none repeat scroll 0 0;
  left: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.sticky .main-menu > nav > ul > li {
  padding: 20px 0;
}

.sticky .logo {
  padding: 10px 0;
}

.header-area .sticky {
  background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.4);
}

.main-menu ul li ul.submenu.megamenu > li:hover > a {
  background: inherit;
  color: #444;
}

.sticky .header-top {
  display: none;
}

.sticky .logo img {
  width: 69%;
}

.sticky.header-area::after {
  height: 57px;
}

.sticky .main-menu ul {
  text-align: right;
}

/*----------------------------------------*/
/*  Mobile Menu
 /*----------------------------------------*/
.mobile-menu-area {
  display: none;
}

.mean-container .mean-bar:after {
  display: none;
}

.mean-container .mean-nav ul li a {
  background: #ffffff none repeat scroll 0 0;
  color: #2d3e50;
  display: block;
}

.mean-container .mean-nav ul li a.mean-expand {
  background: none;
  top: -4px;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: none;
}

.mean-container .mean-nav ul li li a {
  color: #4e4f54;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
  border-top: 0;
}

.mean-container .mean-nav ul li li {
  background: #fff;
}

.mean-container a.meanmenu-reveal {
  color: #2d3e50;
}

.mean-container a.meanmenu-reveal:hover span {
  background: #b5876d none repeat scroll 0 0;
}

.mean-last {
  margin-bottom: 20px;
}

.mobile-menu {
  position: relative;
}
@media screen and (max-width: 768px) {
  .header-contact > span,
  .header-contact > span.theme-text,
  .header-links > a {
    color: #000 !important;
  }
}
@media screen and (max-width: 1024px) {
  .logo {
    margin-left: 0 !important;
  }
}
